import React, { useState, useContext } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
// import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import preloaderGif from "../../assets/img/preloader-3.gif";
import "./Login.css";
import { sessionLogin } from "../../utils/LoginAPI";
import { jwtDecode } from "jwt-decode";
import MainContext from "../../context/MainContext";

const Login = () => { //{ setSigned }
  
  const navigate = useNavigate();
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
 
  const cookies = new Cookies();
  const { setAccessToken, ShowNotification } = useContext(MainContext);

  const [passwordType, setPasswordType] = useState("password");
  
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const validateEmail = (inputText) => {
    //const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (inputText.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const handleSubmit = async () => {
    const userAgent = navigator.userAgent;
    // let mobile_type = /Android/i.test(userAgent) ? "android" : /iPhone|iPad|iPod/i.test(userAgent) ? "iOS" : "unknown";
    const browserMatch = userAgent.match(
      /(chrome|firefox|safari|opera|msie|trident(?=\/))\/?\s*(\d+)/i
    );
  
    const browser = {
      name: browserMatch ? browserMatch[1] : "Unknown",
      version: userAgent.match(/version\/(\d+)/i)
        ? userAgent.match(/version\/(\d+)/i)[1]
        : userAgent.match(/(?:rv|msie|firefox|chrome|safari)\s*\/?\s*(\d+)/i)[1],
    };
  
    // const os = {
    //   name: (() => {
    //     if (userAgent.match(/Android/i)) return "Android";
    //     if (userAgent.match(/iPhone|iPad|iPod/i)) return "iOS";
    //     if (userAgent.match(/Windows/i)) return "Windows";
    //     if (userAgent.match(/Mac/i)) return "Mac OS X";
    //     if (userAgent.match(/Linux/i)) return "Linux";
    //     return "Unknown";
    //   })(),
    //   version: userAgent.match(
    //     /(?:Windows NT\s|Android\s|iPhone\sOS\s|iPad\sOS\s)([^;)]+)/i
    //   )[1],
    // };
    
    // const networkType = navigator.connection ? navigator.connection.effectiveType : 'unknown';

    let requestData = {
      login_type: "email",
      email,
      password,
      // app_version: "1.0.0",
      // mobile_type: "android",
      // mobile_details: {
      //   device: "Device",
      //   model_no: "Web002",
      //   manufacturer: browser?.name,
      //   os_build_version:  browser?.version,
      //   mobile_unique_id: "123123123",
      // },
    };
    
    if (validateEmail(email)) {
      setIsLoginPreLoading(true);

      try {
        const responseDashboardLogin = await sessionLogin(requestData);

        let responseToken = responseDashboardLogin?.data?.access_token;
        if (responseToken) {
          const decodedToken = jwtDecode(responseToken);
          // if (decodedToken?.role) {
            cookies.set("access_token", responseToken, {
              path: "/",
              maxAge: 604800,
            });

            setAccessToken(responseToken);
            // setSigned(true);
            setIsLoginPreLoading(false);
            navigate("/");
          // }else{
          //   ShowNotification("error", "Access denied. Please contact the administrator...");
          // }
        }
      } catch (error) {
        console.error(
          "Error fetching data:",
          error.response ? error.response.data : error.message
        );
        ShowNotification("error", error?.message || error.message);
      } finally {
        setIsLoginPreLoading(false);
      }
    } else {
      ShowNotification("warning", "Please enter valid email-address.");
    }
  };

  //Initial Preloader
  const [isLoginPreLoading, setIsLoginPreLoading] = useState(false); //Initial Loader

  const Preloader = () => {
    return (
      <div className="preloader-section">
        <img src={preloaderGif} alt="Loading..." />
      </div>
    );
  };

  /* Login/Signup Panel */

  const [activePanel, setActivePanel] = useState(false);

  const handlePanelToggle = (status) => {
    setActivePanel(status);
  };

  return (
    <div>
      {isLoginPreLoading && <Preloader />}
      <div className="login-container">
        <div
          className={`container ${activePanel ? "active" : ""}`}
          id="container"
        >
          <div className="form-container sign-up">
            <form>
              <h1>Create Account</h1>

              <span>or use your email for registration</span>
              <input type="text" placeholder="Name" />
              <input type="email" placeholder="Email" />
              <input type="password" placeholder="Password" />
              <button type="submit">Sign Up</button>
            </form>
          </div>
          <div className="form-container sign-in">
            <form>
              <h1 className="log-in-text">Log In </h1>

              <span className="mb-2 log-in-desc">Use your E-mail and Password</span>

              <Form.Group
                className="mb-2 text-start w-100"
                controlId="formBasicEmail"
              >
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => handleChange(e, setEmail)}
                />
              </Form.Group>
              <Form.Group
                className="mb-2 text-start w-100"
                controlId="formBasicPassword"
              >
                <InputGroup>
                  <Form.Control
                    type={passwordType}
                    placeholder="Password"
                    onChange={(e) => handleChange(e, setPassword)}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        handleSubmit();
                      }
                    }}
                  />
                  <InputGroup.Text
                    onClick={togglePassword}
                    style={{ cursor: "pointer" }}
                  >
                    {passwordType === "password" ? (
                      <FontAwesomeIcon icon={faEye} />
                    ) : (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    )}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Button variant="primary" onClick={handleSubmit}>
                Sign In
              </Button>
            </form>
          </div>
          <div className="toggle-container">
            <div className="toggle">
              <div
                className={`toggle-panel toggle-left ${
                  activePanel ? "active" : ""
                }`}
              >
                <h1>Welcome Back!</h1>
                <p>Enter your personal details to use all site features</p>
                <button
                  className="hidden"
                  onClick={() => handlePanelToggle(false)}
                  id="login"
                >
                  Sign In
                </button>
              </div>
              <div
                className={`toggle-panel toggle-right ${
                  activePanel ? "active" : ""
                }`}
              >
                <img
                  src={require("../../assets/img/dh-logo-light-1.png")}
                  alt=""
                  className="login-logo"
                />
                 <img
                  src={require("../../assets/img/dh-logo-text-light.png")}
                  alt=""
                  className="login-logo-text"
                />
                {/* <h1>Deep Holistics</h1>
                <p>Look - Feel - Perform</p> */}
                {/* <button
                  className="hidden"
                  onClick={() => handlePanelToggle(true)}
                  id="register"
                >
                  Sign Up
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
