import axios from "axios";
import Cookies from "universal-cookie";

const { REACT_APP_BASE_URL, REACT_APP_CLIENT_ID } = process.env;

const cookies = new Cookies();

const createAxiosInstance = (baseURL) => {
  return axios.create({
    baseURL: baseURL,
    headers: {
      client_id: REACT_APP_CLIENT_ID,
      access_token: cookies.get("access_token"),
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
};

const preTokenApi = createAxiosInstance(REACT_APP_BASE_URL);

//Interceptors
const applyAccessTokenInterceptor = (apiInstance) => {
  apiInstance.interceptors.request.use(
    (req) => {
      const token = cookies.get("access_token");
      if (token) {
        req.headers["access_token"] = token; // Set Access Token
      }
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  apiInstance.interceptors.response.use(
    (req) => {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
};

applyAccessTokenInterceptor(preTokenApi);


export const getUserProfile = () =>
  new Promise((resolve, reject) => {
    preTokenApi
      .get(`/v2/user/preference`)
      .then((response) => {
        resolve({ status: "success", data: response.data?.data });
      })
      .catch((error) => {
        console.error(error.response)
        reject(error.response);
      });
  });

export const getUserFullProfile = () =>
  new Promise((resolve, reject) => {
    preTokenApi
      .post(`/v1/user/getuserfullprofile`)
      .then((response) => {
        resolve({ status: "success", data: response.data?.data });
      })
      .catch((error) => {
        console.error(error.response)
        reject(error.response);
      });
  });

export const searchHTUsers = (requestData) =>
  new Promise((resolve, reject) => {
    preTokenApi
      .post(`/v3/diagnostics/search-users`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const registerGenebox = (requestData) =>
  new Promise((resolve, reject) => {
    preTokenApi
      .post(`/v3/diagnostics/gene-box/kit/order`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

/* Di Kit */

export const getDiKitList = (requestData) =>
  new Promise((resolve, reject) => {
    preTokenApi
      .get(`/v3/diagnostics/kit`, { params: requestData })
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const getDiKitData = (requestData) =>
  new Promise((resolve, reject) => {
    preTokenApi
      .get(`/v3/diagnostics/kit/${requestData}`)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const getDiOrderData = (requestData) =>
  new Promise((resolve, reject) => {
    preTokenApi
      .get(`/v3/diagnostics/order`, { params: requestData })
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

/* Premium User Get */

export const getHumanTokenUsersList = (requestData) =>
  new Promise((resolve, reject) => {
    preTokenApi
      .get(`/v3/diagnostics/premium/user`, { params: requestData })
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const getHumanTokenUserData = (requestData) =>
  new Promise((resolve, reject) => {
    preTokenApi
      .get(`/v3/diagnostics/premium/user/${requestData}`)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const getCGMExpiryUserActionList = (requestData) =>
  new Promise((resolve, reject) => {
    preTokenApi
      .get(`/v3/diagnostics/cgm-expiry/user-action/`, { params: requestData })
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const getCGMExpiryUserAction = (requestData) =>
  new Promise((resolve, reject) => {
    preTokenApi
      .get(`/v3/diagnostics/cgm-expiry/user-action/${requestData}`)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const updateCGMExpiryUserActionList = (requestData) =>
  new Promise((resolve, reject) => {
    preTokenApi
      .put(`/v3/diagnostics/cgm-expiry/user-action/`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const getClaimHumanTokenUsersList = (requestData) =>
  new Promise((resolve, reject) => {
    preTokenApi
      .get(`/v3/int/human-token/token-users`, { params: requestData })
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const getClaimHumanTokenUsersStatus = (requestData) =>
  new Promise((resolve, reject) => {
    preTokenApi
      .post(`/v3/int/human-token/user-token-delivery`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const updateClaimHumanTokenUsersStatus = (requestData) =>
  new Promise((resolve, reject) => {
    preTokenApi
      .patch(`/v3/int/human-token/user-token-delivery`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const getUserDiOrderData = (requestData) =>
    new Promise((resolve, reject) => {
      preTokenApi
        .post(`/v3/diagnostics/order`, requestData)
        .then((response) => {
          resolve({ status: "success", data: response.data.data });
        })
        .catch((error) => {
          reject(error.response?.data);
        });
    });

export const getHumanTokenWaitlistUsers = (requestData) =>
  new Promise((resolve, reject) => {
    preTokenApi
      .get(`/v3/int/human-token/waitlist-users`, { params: requestData })
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

  export const sendWaitingMail = (requestData) =>
    new Promise((resolve, reject) => {
      preTokenApi
        .post(`/v3/human-token/send/waiting/mail`, requestData)
        .then((response) => {
          resolve({ status: "success", data: response.data.data });
        })
        .catch((error) => {
          reject(error.response?.data);
        });
    });

  export const createCouponForWaitlistUser = (requestData) =>
    new Promise((resolve, reject) => {
      preTokenApi
        .post(`/v3/int/human-token/waitlist/coupon`, requestData)
        .then((response) => {
          resolve({ status: "success", data: response.data.data });
        })
        .catch((error) => {
          reject(error.response?.data);
        });
    });

  export const getHumanTokenUserPIIData = (requestData) =>
    new Promise((resolve, reject) => {
      preTokenApi
        .get(`/v3/int/human-token/pii-data`, { params: requestData })
        .then((response) => {
          resolve({ status: "success", data: response.data.data });
        })
        .catch((error) => {
          reject(error.response?.data);
        });
    });

  export const sendWaitlistMail = (requestData) =>
    new Promise((resolve, reject) => {
      preTokenApi
        .post(`/v3/int/human-token/send/waitlist/mail`, requestData)
        .then((response) => {
          resolve({ status: "success", data: response.data.data });
        })
        .catch((error) => {
          reject(error.response?.data);
        });
    });

  export const sendInvitationMail = (requestData) =>
    new Promise((resolve, reject) => {
      preTokenApi
        .post(`/v3/int/human-token/send/invitation/mail`, requestData)
        .then((response) => {
          resolve({ status: "success", data: response.data.data });
        })
        .catch((error) => {
          reject(error.response?.data);
        });
    });

  export const getHumanTokenLeads = (requestData) =>
    new Promise((resolve, reject) => {
      preTokenApi
        .get(`/v3/int/human-token/lead`, { params: requestData })
        .then((response) => {
          resolve({ status: "success", data: response.data.data });
        })
        .catch((error) => {
          reject(error.response?.data);
        });
    });

  export const sendOnboardEmail = (requestData) =>
    new Promise((resolve, reject) => {
      preTokenApi
        .post(`/v3/int/human-token/lead/onboard/email/send`, requestData)
        .then((response) => {
          resolve({ status: "success", data: response.data.data });
        })
        .catch((error) => {
          reject(error.response?.data);
        });
    });
  
  export const cancelReminderEmail = (requestData) =>
    new Promise((resolve, reject) => {
      preTokenApi
        .post(`/v3/int/human-token/lead/reminder/email/cancel`, requestData)
        .then((response) => {
          resolve({ status: "success", data: response.data.data });
        })
        .catch((error) => {
          reject(error.response?.data);
        });
    });

  export const createCouponForLead = (requestData) =>
    new Promise((resolve, reject) => {
      preTokenApi
        .post(`/v3/int/human-token/lead/coupon`, requestData)
        .then((response) => {
          resolve({ status: "success", data: response.data.data });
        })
        .catch((error) => {
          reject(error.response?.data);
        });
    });

  export const removeCouponForLead = (requestData) =>
    new Promise((resolve, reject) => {
      preTokenApi
        .patch(`/v3/int/human-token/lead/coupon`, requestData)
        .then((response) => {
          resolve({ status: "success", data: response.data.data });
        })
        .catch((error) => {
          reject(error.response?.data);
        });
    });

export const updateLeadUserInterestStatus = (requestData) =>
  new Promise((resolve, reject) => {
    preTokenApi
      .post(`/v3/int/human-token/lead/user-interest/update`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const sendReminderEmailForCallNotAnswered = (requestData) =>
  new Promise((resolve, reject) => {
    preTokenApi
      .post(`/v3/int/human-token/lead/call-not-answered`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const sendCustomEmail = (requestData) =>
  new Promise((resolve, reject) => {
    preTokenApi
      .post(`/v3/int/human-token/lead/custom-email`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

  export const sendEligibilityReminderEmail = (requestData) =>
    new Promise((resolve, reject) => {
      preTokenApi
        .post(`/v3/int/human-token/lead/eligibility/reminder/email/send`, requestData)
        .then((response) => {
          resolve({ status: "success", data: response.data.data });
        })
        .catch((error) => {
          reject(error.response?.data);
        });
    });

    export const addComment = (requestData) =>
      new Promise((resolve, reject) => {
        preTokenApi
          .post(`/v3/int/human-token/lead/comment`, requestData)
          .then((response) => {
            resolve({ status: "success", data: response.data.data });
          })
          .catch((error) => {
            reject(error.response?.data);
          });
      });

    export const updateComment = (requestData) =>
      new Promise((resolve, reject) => {
        preTokenApi
          .put(`/v3/int/human-token/lead/comment`, requestData)
          .then((response) => {
            resolve({ status: "success", data: response.data.data });
          })
          .catch((error) => {
            reject(error.response?.data);
          });
      });

    export const deleteComment = (requestData) =>
      new Promise((resolve, reject) => {
        preTokenApi
          .delete(`/v3/int/human-token/lead/comment`, { data: requestData})
          .then((response) => {
            resolve({ status: "success", data: response.data.data });
          })
          .catch((error) => {
            reject(error.response?.data);
          });
      });
    
    export const updateLeadManager = (requestData) =>
      new Promise((resolve, reject) => {
        preTokenApi
          .post(`/v3/int/human-token/lead/manager/update`, requestData)
          .then((response) => {
            resolve({ status: "success", data: response.data.data });
          })
          .catch((error) => {
            reject(error.response?.data);
          });
      });

export { preTokenApi };
